import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import router from 'next/router';
import { getRoute, PATH } from '@/utils/routes';
import { commonToggleAuthModal } from '@/utils/commonToggleAuthModal';
import { isPLPRequiredSystem } from '@/utils/isPLPRequiredSystem';
import { getAvailablePaymentMethods, getStorePolicies } from '@/redux/actions';
import { useRef } from 'react';
import SubscriberInput from '../../SubscriberInput';
import { handleRouteChangesOnClick } from '@/utils/handleRouteChangesOnClick';
import { isOutsideClick } from '@/utils/isClickOutside';
import { ADDRESS_VISIBILITY } from '@/utils/constants';
import LogoutModal from '@/components/LogoutModal';
import { deviceWidth } from '@/utils/deviceWidth';
import { executeLogout } from '@/utils/excuteLogout';
import { FooterMap3 } from '@/components/FooterMap/index';
import { MAX_MOBILE_WIDTH } from '../../../../utils/constants';
import { useFeatureLocks } from '@/hooks/useFeatureLocks';
import { getFooterDSLabel } from '../footerUtils';
import { removeEmoticons } from '@/utils/emoticonsStatus';
import { useSSRSelector } from '@/redux/ssrStore';
import useStoreLogoOrNameVisibility from '@/hooks/useStoreLogoOrNameVisibility';
import getActualOrDsStoreLogo from '@/utils/getActualOrDsStoreLogo';
import getDummyOrActualContactDetails from '@/utils/getContactDetails';

const MAX_CATEGORIES_ITEMS = 20;

const Footer = (props) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const [shopName, setShopName] = useState('');
  const [categories, setCategories] = useState([]);
  const [navItems, setNavItems] = useState([]);
  const [toggleMoreCategories, setToggleMoreCategories] = useState(false);
  const [mobileCategoriesLength, setMobileCategoriesLength] =
    useState(MAX_CATEGORIES_ITEMS);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [shopOpen, toggleShop] = useState(false);
  const [collectionIdOpen, setCollectionIdOpen] = useState(null);

  const { user, paymentMethods } = useSelector((state) => ({
    user: state.userReducer,
    paymentMethods: state.paymentReducer.availablePaymentMethods,
  }));
  const {
    storeData,
    navigationList,
    storePolicies,
    collections,
    catalogCategories,
    productTags,
  } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
    storePolicies: state.storePolicyReducer.data,
    collections: state.catalogReducer.collections,
    navigationList: state.additionalPagesReducer.navigationList,
    catalogCategories: state.catalogReducer.categories,
    productTags: state.catalogReducer.productTags,
  }));
  const { isStoreLogoInFooter, isStoreNameInFooter } = useStoreLogoOrNameVisibility();
  const storeLogo = getActualOrDsStoreLogo(storeData);
  const { storeAddress, storePhone } = getDummyOrActualContactDetails(storeData, true);
  const { leadGenFeatureStatus, visitorLoginFeatureStatus } = useFeatureLocks();
  const isLeadGenVisible = leadGenFeatureStatus?.isVisible;
  const isVisitorLoginVisible = visitorLoginFeatureStatus?.isVisible;

  const headerName = storeData?.theme?.components?.Header?.[0]?.name;
  const storeEmail = storeData?.owner?.email_id;
  const isLoginVisible = props?.config?.hidden_components?.login_view;
  const dsLabel = getFooterDSLabel(storeData?.theme?.components);

  useEffect(() => {
    if (deviceWidth > MAX_MOBILE_WIDTH)
      document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setShowLogoutConfirmation(false);
  }, [user.isLogin]);

  useEffect(() => {
    if (deviceWidth < 766) {
      toggleShop(false);
    } else {
      toggleShop(true);
    }
  }, [deviceWidth]);

  useEffect(() => {
    toggleMoreCategories
      ? setMobileCategoriesLength(categories.length)
      : setMobileCategoriesLength(MAX_CATEGORIES_ITEMS);
  }, [toggleMoreCategories]);

  useEffect(() => {
    setCategories([...productTags, ...catalogCategories]);
  }, [productTags, catalogCategories]);

  useEffect(() => {
    setNavItems([]);
    const tempNavList = [];
    navigationList.map((nav) => {
      if (nav.slug_url === 'shop') {
        setShopName(nav.page_name);
      } else {
        tempNavList.push(nav);
      }
    });
    setNavItems(tempNavList);
  }, [navigationList]);

  useEffect(() => {
    if (storeData?.store_id && !storePolicies?.length) {
      dispatch(getStorePolicies({ store_id: storeData.store_id }));
    }
  }, [storeData?.store_id, storePolicies?.length]);

  useEffect(() => {
    if (storeData?.store_id) {
      dispatch(
        getAvailablePaymentMethods({
          store_id: storeData?.store_id,
        })
      );
    }
  }, [storeData?.store_id]);

  const handleClickOutside = (e) => {
    if (!isOutsideClick(ref, e.target)) {
      setToggleMoreCategories(false);
    }
  };

  const hidePaymentMethod = (paymentMethod) => {
    switch (paymentMethod.payment_id) {
      case 'paytm':
      case 'amexCard':
        return true;
      default:
        return false;
    }
  };

  const toggleShopDropdown = () => {
    if (deviceWidth < 766) {
      toggleShop(!shopOpen);
    }
  };

  const handleNavbarItemClick = (nav) => {
    nav.is_editable
      ? handleRouteChangesOnClick(PATH.additionalPage(nav.slug_url))
      : handleRouteChangesOnClick(nav.slug_url);
  };

  const renderNavType = (nav) => {
    switch (nav.page_type) {
      case 'more': {
        return (
          <span
            className="footer-nav mb3"
            onClick={() => handleNavbarItemClick(nav)}
            key={nav.page_id}
          >
            <div>
              {nav.page_name}
              {nav?.subNavs?.length > 0 && (
                <img alt="dropdown button" src="/assets/images/angleright.png" />
              )}
            </div>
          </span>
        );
      }
      case 'blank_page': {
        return nav.is_external_url ? (
          <span className="footer-nav mb3" key={nav.page_id}>
            <a
              style={{
                color: '#000000',
                fontSize: 14,
              }}
              className="link"
              href={nav.slug_url}
              target="_blank"
              rel="noreferrer"
            >
              {nav.page_name}
            </a>
          </span>
        ) : (
          <span
            className="footer-nav mb3"
            onClick={() => handleNavbarItemClick(nav)}
            key={nav.page_id}
          >
            {nav.page_name}
          </span>
        );
      }
      default: {
        return (
          <span
            className="footer-nav mb3"
            onClick={() => handleNavbarItemClick(nav)}
            key={nav.page_id}
          >
            {nav.page_name}
          </span>
        );
      }
    }
  };

  function getCurrentYear() {
    return new Date().getFullYear() || '';
  }

  const onCategoryClick = (category, e = null) => {
    if (router.router.pathname.includes('/shop')) window.scrollTo(0, 0);

    if (isPLPRequiredSystem(storeData?.theme?.theme_class)) {
      router.push(
        getRoute(`${PATH.SHOP}?cid=${category.id}`, storeData?.store_info?.domain),
        undefined,
        { shallow: true }
      );
    } else {
      router.push(
        getRoute(`${PATH.PRODUCT}?cid=${category.id}`, storeData?.store_info?.domain),
        undefined,
        { shallow: true }
      );
    }
  };

  function handleToggleMoreCategories() {
    setToggleMoreCategories(!toggleMoreCategories);
  }

  const toggleAuthModal = () => {
    if (
      commonToggleAuthModal({
        userLogin: user?.isLogin,
        storeInfoDomain: storeData?.store_info?.domain,
      })
    )
      toggleLogoutModal();
  };

  function toggleLogoutModal() {
    setShowLogoutConfirmation(!showLogoutConfirmation);
  }

  const collectionToggle = () => {
    if (shopOpen) setCollectionIdOpen(null);

    toggleShop(!shopOpen);
  };

  const collectionDropdownToggle = (colId) => {
    if (collectionIdOpen === colId) setCollectionIdOpen(null);
    else setCollectionIdOpen(colId);
  };

  const onCollectionClick = (id) => {
    router.push(getRoute(PATH.collectionCategories(id), storeData?.store_info?.domain));
  };

  const renderDesktopCategories = () => {
    return (
      <div className="flex flex-row justify-between">
        <div className="store-information desktop-hr">
          {shopOpen &&
            categories?.map((item, index) => {
              return index % 2 === 0 && index < MAX_CATEGORIES_ITEMS ? (
                <span
                  className="footer-nav mb3"
                  onClick={() => onCategoryClick(item)}
                  key={item.id}
                >
                  {removeEmoticons(item.name) || 'Available Items'}
                </span>
              ) : null;
            })}
        </div>
        {
          <div className="store-information ml4 desktop-hr">
            {shopOpen &&
              categories?.map((item, index) => {
                return (
                  <>
                    {index % 2 !== 0 && index < MAX_CATEGORIES_ITEMS - 1 ? (
                      <span
                        className="footer-nav mb3"
                        onClick={() => onCategoryClick(item)}
                        key={item.id}
                      >
                        {removeEmoticons(item.name) || 'Available Items'}
                      </span>
                    ) : null}
                  </>
                );
              })}
            {categories?.length >= MAX_CATEGORIES_ITEMS && (
              <div
                className="desc-text__view-more fw5 pointer underline-hover desktop-hr mv0 relative"
                onClick={handleToggleMoreCategories}
              >
                More...
                {toggleMoreCategories && (
                  <>
                    <div className="store-information_arrowBox store-information_arrowBox_shadow z-5"></div>
                    <div
                      ref={ref}
                      className="z-5 store-information store-information_arrow ph3 shadow-3 pt3 overflow-y-auto overflow-x-hidden"
                    >
                      {categories?.map((item, index) => {
                        return index >= MAX_CATEGORIES_ITEMS - 1 ? (
                          <span
                            className="footer-nav mb3 fw4"
                            onClick={(e) => onCategoryClick(item, e)}
                            key={item.id}
                          >
                            {removeEmoticons(item.name) || 'Available Items'}
                          </span>
                        ) : null;
                      })}
                    </div>{' '}
                  </>
                )}
              </div>
            )}
          </div>
        }
      </div>
    );
  };

  const renderMobileCategories = () => {
    return (
      <div className="w-100">
        <div className="store-information mobile-hr-shop">
          {shopOpen &&
            categories?.length > 0 &&
            categories?.slice(0, mobileCategoriesLength)?.map((item) => {
              return (
                <span
                  className="footer-nav mb3"
                  onClick={() => onCategoryClick(item)}
                  key={item.id}
                >
                  {removeEmoticons(item.name) || 'Available Items'}
                </span>
              );
            })}
        </div>
        {categories?.length >= MAX_CATEGORIES_ITEMS && shopOpen && (
          <span
            className="desc-text__view-more fw5 pointer underline-hover mobile-hr-shop mt0 mb3"
            onClick={handleToggleMoreCategories}
          >
            {toggleMoreCategories ? 'Show Less...' : 'More...'}
          </span>
        )}
      </div>
    );
  };

  function renderLoginLogoutDesktop() {
    return headerName === 'Header14' ? (
      isLoginVisible ? (
        <span className="footer-nav mb3 fw6" onClick={toggleAuthModal}>
          {user?.isLogin ? 'Logout' : 'Login'}
        </span>
      ) : null
    ) : (
      <span className="footer-nav mb3 fw6" onClick={toggleAuthModal}>
        {user?.isLogin ? 'Logout' : 'Login'}
      </span>
    );
  }

  function renderLoginLogoutMobile() {
    return headerName === 'Header14' ? (
      isLoginVisible ? (
        <>
          <h4 style={{ fontSize: 14 }} onClick={toggleAuthModal}>
            {user?.isLogin ? 'Logout' : 'Login'}
          </h4>
          <hr className="mobile-hr" />
        </>
      ) : null
    ) : (
      <>
        <h4 style={{ fontSize: 14 }} onClick={toggleAuthModal}>
          {user?.isLogin ? 'Logout' : 'Login'}
        </h4>
        <hr className="mobile-hr" />
      </>
    );
  }

  function authAndLogoutModal() {
    return (
      showLogoutConfirmation && (
        <LogoutModal
          toggleLogoutModal={toggleLogoutModal}
          executeLogout={executeLogout}
          showLogoutConfirmation={showLogoutConfirmation}
        />
      )
    );
  }

  /**
   * Checks if given value is hidden or not
   * @param {*} visibilityValue
   * @return {boolean} hidden or visible
   */
  const addressVisibilityHidden = (visibilityValue) =>
    storeData?.address?.visibility === visibilityValue;

  /**
   * Store Policy and footer component
   * @return {JSX}
   */
  const StorePolicyAndFooterBottomSection = () => {
    return (
      <>
        {storeData?.address?.visibility === ADDRESS_VISIBILITY?.VISIBLE && <hr />}
        <div className="Footer3__bottom-section flex flex-column items-center">
          {storePolicies.length > 0 && (
            <div className="Footer3__section store-policies flex justify-around w-100 f6">
              {storePolicies.map((page) => {
                return (
                  <p
                    name={`page${page.page_type}`}
                    className="footer-nav"
                    onClick={() =>
                      handleRouteChangesOnClick(PATH.STORE_POLICIES, {
                        page_type: page.page_type,
                      })
                    }
                    key={page.id}
                  >
                    {page.page_name}
                  </p>
                );
              })}
            </div>
          )}

          {!props?.config?.hidden_components?.payment_method_images_not_visible && (
            <div
              style={{ display: 'none' }}
              className="payment-methods mt3 flex items-center"
            >
              {paymentMethods.length > 0 &&
                paymentMethods.map((paymentMethod, index) => {
                  if (hidePaymentMethod(paymentMethod) || paymentMethod.status == 0) {
                    return null;
                  } else {
                    return (
                      <img
                        alt={`payment menthod ${index + 1}`}
                        key={paymentMethod.image_url}
                        src={paymentMethod.image_url}
                        className="mr3 mb0-ns mb3"
                        height="18px"
                      />
                    );
                  }
                })}
            </div>
          )}
          <p className="footer__bottom-text">
            Copyright {getCurrentYear()}
            {dsLabel ? <>. {dsLabel}</> : ''}
          </p>
        </div>
      </>
    );
  };

  const NavigationComponent = () => {
    if (collections?.length) return null;

    return (
      categories?.length > 0 && (
        <div className="flex flex-column f6 ttc w-30">
          <hr className="mobile-hr" />
          <h4 className="pointer" onClick={toggleShopDropdown}>
            {shopName || 'Shop'}
            <img
              className="right-2 absolute dn-ns"
              width="8px"
              alt="dropdown button"
              src="/assets/images/angleright.png"
              style={{
                transform: shopOpen ? `rotate(-90deg)` : `rotate(90deg)`,
              }}
            />
          </h4>
          {deviceWidth < 767 ? renderMobileCategories() : renderDesktopCategories()}
          <hr className="mobile-hr" />
        </div>
      )
    );
  };

  /**
   * collections listing component for desktop only
   * if collection is not part of store config
   * it is not renderred
   * @return {null | JSX}
   */
  const DesktopCollectionsComponent = () => {
    if (!props?.includeCollectionSet || collections.length === 0) return null;

    return (
      <div className="Footer3__collections-component">
        <h2 className="collections-component--header">Collection</h2>
        <div className="collections-component__body">
          {collections?.length
            ? collections.map((collection) => (
                <div className="collection-item" key={collection.id}>
                  <h3
                    className="collection-item--header pointer"
                    onClick={() => onCollectionClick(collection.id)}
                  >
                    {collection.name}
                  </h3>
                  <ul className="collection-item__item-list">
                    {collection?.categories?.length
                      ? collection?.categories.map((category) => {
                          return (
                            <li
                              className="collection-item--name"
                              key={category.id}
                              onClick={() => onCategoryClick(category)}
                            >
                              {category.name}
                            </li>
                          );
                        })
                      : null}
                  </ul>
                </div>
              ))
            : null}
        </div>
        <hr />
      </div>
    );
  };

  const MobileCollectionsComponent = () => {
    if (!props?.includeCollectionSet || collections?.length === 0) return null;
    return (
      <>
        <hr className="mobile-hr" />
        <div className="accordion-component">
          <h3 className="accordion-header fw6" onClick={collectionToggle}>
            Shop
            <img
              className="ml3"
              width="8px"
              alt="dropdown button"
              src="/assets/images/angleright.png"
              style={{
                transform: `rotate(${shopOpen ? '270deg' : '90deg'})`,
              }}
            />
          </h3>
          {shopOpen ? (
            <div>
              {collections?.length
                ? collections?.map((collection) => (
                    <div className="accordion-body" key={collection.id}>
                      <h3
                        className="accordion-header fw5 ttu"
                        onClick={() => collectionDropdownToggle(collection.id)}
                      >
                        {collection.name}
                        <img
                          className="ml3"
                          width="8px"
                          alt="dropdown button"
                          src="/assets/images/angleright.png"
                          style={{
                            transform: `rotate(${
                              collectionIdOpen === collection.id ? '270deg' : '90deg'
                            })`,
                          }}
                        />
                      </h3>
                      {collectionIdOpen === collection.id && (
                        <ul>
                          {collection?.categories?.length
                            ? collection?.categories?.map((category) => (
                                <li
                                  key={category.id}
                                  onClick={() => onCategoryClick(category)}
                                >
                                  {category.name}
                                </li>
                              ))
                            : null}
                        </ul>
                      )}
                    </div>
                  ))
                : null}
            </div>
          ) : null}
        </div>
        <hr className="mobile-hr" />
      </>
    );
  };

  const RenderSocialMediaProfileDetails = () => {
    if (
      !storeData.social_media_profile_details?.media_count ||
      storeData.social_media_profile_details?.media_count < 1
    )
      return null;
    return (
      <div
        className="footer-social-media-container"
        style={{ width: '246px', marginBottom: '1rem' }}
      >
        <h4>Follow us here</h4>
        <div className="social-media-section flex space-between">
          {storeData.social_media_profile_details?.media_detail.map((profile) => {
            return profile.profile_url !== '' ? (
              <div key={profile.id} className="social-media-icon">
                <a href={profile.profile_url} target="_blank" rel="noreferrer">
                  <img src={profile.image_url} alt={`${profile?.name} image`} />
                </a>
              </div>
            ) : null;
          })}
        </div>
      </div>
    );
  };

  const FooterBottomComponent = () => {
    return (
      <div className="store-information mt-13">
        {isLeadGenVisible ? (
          <div className="store-information__subsection justify-start-ns justify-center">
            <SubscriberInput />
          </div>
        ) : null}
        {storeAddress && (
          <div>
            <h4>Visit our store</h4>
            <p className="address-text fw4 f6">{storeAddress}</p>
          </div>
        )}
        <div>
          {!!storeEmail && (
            <div
              className="store-information__subsection justify-start-ns justify-center"
              style={{ marginTop: 40 }}
            >
              <span>
                <img alt="mail" src="https://cdn.dotpe.in/kiranaStatic/icons/mail.png" />
              </span>
              <a href={`mailto:${storeEmail}`} className="address-text fw4 f6">
                {storeEmail}
              </a>
            </div>
          )}
          {!!storePhone && (
            <div
              className="store-information__subsection justify-start-ns justify-center"
              style={{
                marginTop: storeEmail ? 10 : 40,
              }}
            >
              <span className="flex">
                <img
                  alt="phone"
                  src="https://cdn.dotpe.in/kiranaStatic/icons/ringingtelephone.png"
                />
              </span>
              <a href={`tel:${storePhone}`} className="address-text fw4 f6">
                {storePhone}
              </a>
            </div>
          )}
        </div>
        {storeData?.social_media_profile_details && <RenderSocialMediaProfileDetails />}
      </div>
    );
  };

  return (
    <>
      {authAndLogoutModal()}
      <footer className="Footer3">
        <div
          className="Footer3__top-section"
          style={{
            maxWidth: addressVisibilityHidden(ADDRESS_VISIBILITY?.HIDDEN)
              ? '1200px'
              : '100%',
            padding: addressVisibilityHidden(ADDRESS_VISIBILITY?.HIDDEN) ? '0 2rem' : '0',
          }}
        >
          <FooterMap3 storeData={storeData} />
          {!router?.router?.pathname.includes(PATH.ABOUT_US) && (
            <div className="Footer3__section tc footer-shop-desc">
              <div className="tw-mb-5 tw-flex tw-flex-col tw-items-center tw-gap-5">
                {storeLogo && isStoreLogoInFooter && (
                  <img src={storeLogo} height="67" width="67" alt="store logo" />
                )}
                {isStoreNameInFooter && (
                  <h2 className="tw-m-0">{storeData?.store_info?.name}</h2>
                )}
              </div>
              {(!!storeData?.store_info?.description ||
                storeData?.store_info?.description.length > 0) && (
                <div className="bottom-section">
                  {storeData?.store_info?.description.length > 160 ? (
                    <p className="desc-text">
                      {storeData?.store_info?.description.substr(0, 160)}...
                    </p>
                  ) : (
                    <p className="desc-text">{storeData?.store_info?.description}</p>
                  )}
                </div>
              )}
              {storeData?.store_info?.description.length > 160 && (
                <p
                  className="desc-text__view-more fw5 pointer underline-hover"
                  onClick={() => handleRouteChangesOnClick(PATH.ABOUT_US)}
                >
                  View More
                </p>
              )}
            </div>
          )}

          {/* collections component desktop */}
          {deviceWidth >= 787 && <DesktopCollectionsComponent />}

          <hr className="mobile-hr" />
          <div className="Footer3__section flex justify-evenly center w-80">
            {navItems?.length > 0 && (
              <div className="flex flex-column f6">
                <h4>{storeData?.store_info?.name}</h4>

                {navItems.map((nav) => renderNavType(nav))}

                {/* render logout component in desktop view */}
                {deviceWidth > 767 && isVisitorLoginVisible && renderLoginLogoutDesktop()}
              </div>
            )}

            {/* Navigation component */}
            <NavigationComponent />

            {/* Mobile logout component, render only in mobile view */}
            {!props.includeCollectionSet &&
              deviceWidth < 767 &&
              isVisitorLoginVisible &&
              renderLoginLogoutMobile()}

            {/* collections component mobile */}
            {deviceWidth < 786 && <MobileCollectionsComponent />}

            {props.includeCollectionSet &&
              deviceWidth < 767 &&
              isVisitorLoginVisible &&
              renderLoginLogoutMobile()}

            <FooterBottomComponent />
          </div>
        </div>
        <StorePolicyAndFooterBottomSection />
      </footer>
    </>
  );
};

export default Footer;
